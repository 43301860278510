import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The login pattern lets the user authenticate with a system for authorized access to product functionality.`}</p>
    </PageDescription>
    <h4 {...{
      "id": "status"
    }}>{`Status:`}</h4>
    <p><a parentName="p" {...{
        "href": "/experimental/overview"
      }}>{`Experimental`}</a></p>
    <h4 {...{
      "id": "maintainer"
    }}>{`Maintainer:`}</h4>
    <p><a parentName="p" {...{
        "href": "https://github.com/creckling"
      }}>{`Chris Reckling`}</a></p>
    <h2 {...{
      "id": "overview"
    }}>{`Overview`}</h2>
    <p>{`Login patterns ensure a user can authenticate into a system so they can access their data securely. Mobile login screens should follow the same pattern and illustration style as the desktop experience.`}</p>
    <p>{`The “Forgot Password” pattern is separate from the authentication and login pattern. If there are several products requiring a login, a user should be able to choose which product to log into.`}</p>
    <h2 {...{
      "id": "login-form-variations"
    }}>{`Login form variations`}</h2>
    <h3 {...{
      "id": "centered-login-form"
    }}>{`Centered login form`}</h3>
    <p>{`The centered login includes copyright information in the footer. Refer to your product content team for these details.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "53.22230267921796%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABwUlEQVQoz5WRTU8iQRCGW4xuoh48LZDwK7iQ6AG48CP2tAcvKwf0tB8aT+ovMZ496InsCdC4rBsuSiR+Mt04MMPMYJhB0qRrqzrO+pmNdvKm0umqJ0/NsOWvK2s/vn9bXd/YXPr0eWFxfm4un81m85lMRiedTj/PF8wS9uQYnkQi8SEWi81ipjGT7PikDtXqb/hTPYRfP3ehXKlABVMul3Uq9/cwpVIJarUaFIvFHQKmUqkZBCWi0egU1nEW+D50bHtomUI6l8fS8W6l53nStm1pWZZ0HEd2u93HuRsMBoB1i4C5XI5AHxFIpoy5ngeGYUhDtJRxfaXs+qHqdCxlmqZCsBqNRgrhqtlsKuyjSNd1aWabgIVCYSIej0+w8PR6PRCcS9FqAb88A7d+AK7rAQ3RWxAEZEMAEEJQpJ4RQgMBYIwqGjJt+AC8AX5FwH0gawI6CEJLwNVfADnnGthoNCLJZJKF0EdAE/j5KfSO9qDf98H3+zBAu+Fw+F9DrBEMe2VlNLy4B/oE9CE8ZInf8J1AbOZGE9rGhV6x025rM1odfxCt+HYgx6ZwgIuWXo/uVMns3xtWzDsMafDB5EneavgXp0RhEiaA3ZkAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Wireframe of a centered login form",
        "title": "Wireframe of a centered login form",
        "src": "/static/4a09b5c8e456ae5a9d6bb943987dd619/fb070/login-background-Image.png",
        "srcSet": ["/static/4a09b5c8e456ae5a9d6bb943987dd619/d6747/login-background-Image.png 288w", "/static/4a09b5c8e456ae5a9d6bb943987dd619/09548/login-background-Image.png 576w", "/static/4a09b5c8e456ae5a9d6bb943987dd619/fb070/login-background-Image.png 1152w", "/static/4a09b5c8e456ae5a9d6bb943987dd619/c3007/login-background-Image.png 1381w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Wireframe of a centered login form</Caption>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "54.19207317073172%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAABEElEQVQoz6XSzUqEUBQHcFORCBfOVlDwY6v4UiFYO5XxLUZqsKB9b9CmjUzopmWr9pIulBTxM5xuB2NiQoMZ+i0OB87537O5GMdxoihKksTz/GKHpumz3xiGWUxgs1iWlWUZXpRHgiBQFDWzd7Lz3UMlCML3/a7rkiSJoiiO4zRNFUWBEY7j2F9+wkEQQDjLsjzP27ZtmkZV1UPDYRgOw1AURV3X0EA94jKEEdp2bdnUFVyuquqIy5un4K1Ar1H9XjYffV+W5aGXTynycfO8fEDn99uX+BMh1Ha9pmkzYWKCJEnLsr3bu5W7vrpe33ie67rwHWAZRvub2L/oun4xuhxBYxiGaZqO4yz32LZtTXwBv1uRTQQD8wIAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of a centered login form",
        "title": "Example of a centered login form",
        "src": "/static/b4c771843d40adb64e9a795b41514ebc/fb070/login-pattern-example1.png",
        "srcSet": ["/static/b4c771843d40adb64e9a795b41514ebc/d6747/login-pattern-example1.png 288w", "/static/b4c771843d40adb64e9a795b41514ebc/09548/login-pattern-example1.png 576w", "/static/b4c771843d40adb64e9a795b41514ebc/fb070/login-pattern-example1.png 1152w", "/static/b4c771843d40adb64e9a795b41514ebc/e8e91/login-pattern-example1.png 1312w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Example of a centered login form</Caption>
    <p>{`If you choose to include an illustration, ensure the art reflects the product. Keep it simple and aligned with the product’s aesthetic.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "54.19207317073172%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAACKUlEQVQoz2OQlZVVUlJSVpCVMwoUNogW0IsS0PDm4eHm4uTk4gKS7FyiGly8wgICAoJCQoKogIGBkZmBgYFNNVS19r9i9T/9ln9Slf9lHEpV5CWUVdRUlBVV9WwVlVTZWBgZIICRCYjBJIgGYlbBxAu67f9ly/9adf036/x/9tGfb5/evXjx8vHjJ8+ePHz9+pV+zCoGNnmQDkaYKVDAJiCY80m17r9k6T/FaqD9/2+/+P3l49s3b9+9f//h+/fv3759MwibxcAswcTEyKRbwey8iUkzj4GJFaqdP3yXYe9/87ZfOo1/NZv+333978fXjx8/ff769eufP3+/fv2iq6sLVMZqOYk16z9ryk/WzP/MBo0QPzCwiRuqFT816v2v0fFfpeTlxVsv/v/99vP7l29fvwBt/vLlq56uHgMDM3voDdak/6yx31lT/rMEnYMGAojgEGVUi2PQSGTmkdm/b+/TD/+vP/7y7tO3X79+ffr8TRekmYHNYwfI5vifQJLFYRnc3+CAAwcGBxvLroOnyrf+T13299Kzf////wf62kAf5GxmQU0Wr6OsMV9Y3PcyckkxMCMACzMLGxCxsLKWlJRMmzG7f+LUSRN6pq85P2nXf1lVI6BmFiZmZiDiEmVmZGZGC3VUAI5JNnkG95cMof8ZbLYhPAgH6enp2WCQAwZARlZWVnFxcUVFZXlJQUXH4qq1/6tW/69Y8r6krKqkuLikpLSktLQEDADnGdx7yDYBoQAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of a centered login form with illustration",
        "title": "Example of a centered login form with illustration",
        "src": "/static/c0944ddf5dabc9d4fe309c7f88b2836a/fb070/login-pattern-example2.png",
        "srcSet": ["/static/c0944ddf5dabc9d4fe309c7f88b2836a/d6747/login-pattern-example2.png 288w", "/static/c0944ddf5dabc9d4fe309c7f88b2836a/09548/login-pattern-example2.png 576w", "/static/c0944ddf5dabc9d4fe309c7f88b2836a/fb070/login-pattern-example2.png 1152w", "/static/c0944ddf5dabc9d4fe309c7f88b2836a/e8e91/login-pattern-example2.png 1312w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Example of a centered login form with illustration</Caption>
    <h3 {...{
      "id": "login-form-on-the-right"
    }}>{`Login form on the right`}</h3>
    <p>{`Right-side logins are ideal for editorial experiences and instances where there is visual content featured on the left side of the window.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "53.29471397538016%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAB1klEQVQoz5XSQYvTQBQH8LiiFdSr7aXXfoiVUgNe+wW8CN6WXmSheBAXj6KfQrx6Vm8ebIuswd3Vpdt2pdbOpF3ZJJMuSbNl0vn7Jm3aqCg68GdeIPkx702M7QcPdx7vPNp+8vTZ1p2792o3Nzdrt0yzVqlU/pQtyn3TNG8btIrFYi6fz18vFApXKBeNw/YRPlgW9qxd7L59g0azhWazuUirhUbjHQ4O9tHt9tDpdNBut8E5h2VZLzVYLpc1ViT0Gu05YxqGOHXcc2fMpeh/kq4npOu6STzPlaeOIwFkc47Feq7BarV6laAbBOb0syF8H4wxyThXbHSiqF6H24r1e0p82Vee8JXjOCqKIo1CSvlCf1+v1y8TeEnXhBrGZDIBt21pUxtJbHsRqvloDLvfhei8B4EQngff9xMwjuMEpPJCihG8BDmXGtGzGQ6Hyb4Ge/CPLQh6TwgBPQql1Aqk541SqWT8fMIMOBgM1uD4O+zjzzjbe41gGkHPO215Pp8nIMEbFGO1NEiY5Nl205bHJwQeYvLxFc6CKYIgwPJiViCVv4PZE6ZZzHME9u0rAmcEGceYzWYa+ncwG77ch4whjNI/Bf93wl9b1jujSwrDpFXoy6D8FfwBnTVrTourQ6sAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Wireframe of right-sided login form",
        "title": "Wireframe of right-sided login form",
        "src": "/static/4a6472bb5e2d6344d8c19bbda9780070/fb070/login-split-screen.png",
        "srcSet": ["/static/4a6472bb5e2d6344d8c19bbda9780070/d6747/login-split-screen.png 288w", "/static/4a6472bb5e2d6344d8c19bbda9780070/09548/login-split-screen.png 576w", "/static/4a6472bb5e2d6344d8c19bbda9780070/fb070/login-split-screen.png 1152w", "/static/4a6472bb5e2d6344d8c19bbda9780070/c3007/login-split-screen.png 1381w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Wireframe of right-sided login form</Caption>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "54.19207317073172%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAABzUlEQVQoz2OQlZVVUlJWVpSXEePn5+UU4GYS4OcVFBQSBAEhIQEeXh5ufX396Ojo8PDwCFTAwMDACMQMHMKclvXcvuuZTOoY2AVBIoxMQIJFSBNI5uZk/////8+fP/9RAVgrI4tMwmHV9v+67b+AJGfgLqiJQM3c4kAyJzsLqPTbt2+fP39++/btmzdvvn79CtIMAgq+Sg3/pUr/6DX9ser8I1/3n0HGDSTOxMIMsp4pr2M7UClQz8uXr4Dg/fv3P378gGpmVI9VaPgvW/5Hs+G/QuVvmdr/jCrhUM1g3bkVvUClnz59+vjxI9DOX79+QbwAdh+nhFzxM/MJ/217/6i1/ZfLv8/AJgJ2NyMLCzPY2RlApV8+f/r+7cv379+A+n/+/Am2GRww3HJWcimn5Us+SCed4pbUYRA0ZBQ2BvmZhRWkOScXqPT9p+9vP/38+h1o8a/fv3/D/AwFjAyMnBCzGPlUGHgU0DS3bfkTMOX/0hOgcP777z9CMyMQgIOYERZJ0NBm4wJ6PScL5OwXH/4+fPP//VekqMIOIPqZ2dldNzH4PM7rOwqK57//0eMZj2ZGMQuO9P8MUf8zF/78+/Pjl6/fv3wFAWCEQ0gAzv4Tbj6wv6gAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of right-sided login form",
        "title": "Example of right-sided login form",
        "src": "/static/30cdf156e45d10f42376bef32633ed78/fb070/login-pattern-example3.png",
        "srcSet": ["/static/30cdf156e45d10f42376bef32633ed78/d6747/login-pattern-example3.png 288w", "/static/30cdf156e45d10f42376bef32633ed78/09548/login-pattern-example3.png 576w", "/static/30cdf156e45d10f42376bef32633ed78/fb070/login-pattern-example3.png 1152w", "/static/30cdf156e45d10f42376bef32633ed78/e8e91/login-pattern-example3.png 1312w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Example of right-sided login form</Caption>
    <h3 {...{
      "id": "login-page-with-product-selector"
    }}>{`Login page with product selector`}</h3>
    <p>{`In some cases, you may ask the user to select which product to log into. This allows the user to jump directly to the home landing page for the product after authenticating.`}</p>
    <p>{`Use a dropdown component to display the options.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "53.14989138305576%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABjUlEQVQoz7WQQU7CQBiFa03UGE3cAQn3IKxg6Q0IB2Ahbkw0Rhe68jJG1x5CwpLAQqIWrKi1pTOY0plhnv8USpBEjQtf8vV1mpmvf2sdnl2cnxwfnVFOq9VqvVgs1svl8n6pVKp/wx5xQHt2LUo+n1/LZrPbxAaxarmtW9w7ffR6DrrdLtrtNjqdzryX71utFu3todFo3BhhoVDYIlE+k8nsGKmFSYCx14+DkCshYgngN8aY5tIIK5XKei6X2yThillbgAIfPMj3YKiHQaA55zoax3ospFYTrZWGnkyUFkLoKIo0xUihlLoy52ltW7PQhEYoEPTvpDt4wbPrYvDyCvb2jNDpQHEf0nOwFGUuJLqeDgS7VqtZNGGCeQDPD6Tz+Jj8m/6Ti4gPIZiH+IMhDj0IqRDHcUoipE6E1Haz2UwmnAt935ee5yElZAxs9AHGOUHNQoKlKJrOdCKktglrHiOk/yZHoxFSaA1Ohzlns+aLqNmZREhtE1+F9Aa5MEHCkuT/heknfyv8QfAn4SeJSX50tNy9cgAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Wireframe of login page with product selector",
        "title": "Wireframe of login page with product selector",
        "src": "/static/99002e11f661042f18482d24591809d1/fb070/login-landing-page-dropdown.png",
        "srcSet": ["/static/99002e11f661042f18482d24591809d1/d6747/login-landing-page-dropdown.png 288w", "/static/99002e11f661042f18482d24591809d1/09548/login-landing-page-dropdown.png 576w", "/static/99002e11f661042f18482d24591809d1/fb070/login-landing-page-dropdown.png 1152w", "/static/99002e11f661042f18482d24591809d1/c3007/login-landing-page-dropdown.png 1381w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Wireframe of login page with product selector</Caption>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1024px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "44.23828125%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsSAAALEgHS3X78AAAB9UlEQVQoz42OS28SURiGx727xmhtmXRmECgM2BmGDsydYebMDQ6XyCVUShND1SZsNLJpYsNSd8aNofUKsRT2LkyM0T9kpYEaxODxH/gl77t6nzwfFqGj23xyM0JHaDpMR3HCv0EQFEWRJBUIBEOhTf+tQDAYQqH8fpIkcRwnCMKH42s+HBPVtKSmdWCnTTue4JmUxuuuoGUMEzieZ9mOaTkwXygUSwawkYbjuASfDIRjYYbHFN00Ha9UrpQqtWw+X9/d2z9o15qtYrkKLMCyjCTJjpdFuKrKHBtjE9u3udRWIolATEcCWLq7u1dvNGGxVN1p7D88uNe6f6dSc72c7XqSEHeAWoS2qXACR6dEWdUNSdOBm8PUDIB5WK5WyrUdNwuNjFHK2dDgc9At1+ouLAiyZtum51qaKiGT5ToZYIqSwrJxTEkbjWbzqNs9fNp90ul0HrWBUyfs56z9AGYtLWMJqu7lIPpOklVJVuS0qegWw7DUBo7hpB8xl5e/ptPp78Wf5XLZPjrGyMeU3NpiwklBFBUNACBK4s11fOXG2rVV35WrqyvX16OxKPaqd/L12/efk8mP8/PZbIbgL58/vXh2ODztD07PhqPx2Wg8HqOMPg6Hrz8Mjt+8e9l73zt5O+j3MbReLBaTi4vJBNW/ns/ny/+7vz5y2MN6h+0fAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of login page with product selector",
        "title": "Example of login page with product selector",
        "src": "/static/2a8321be97d448a393eca2e756f7be12/f935e/login-pattern-example4.png",
        "srcSet": ["/static/2a8321be97d448a393eca2e756f7be12/d6747/login-pattern-example4.png 288w", "/static/2a8321be97d448a393eca2e756f7be12/09548/login-pattern-example4.png 576w", "/static/2a8321be97d448a393eca2e756f7be12/f935e/login-pattern-example4.png 1024w"],
        "sizes": "(max-width: 1024px) 100vw, 1024px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Example of login page with product selector</Caption>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      